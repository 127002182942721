<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <v-skeleton-loader v-if="api.isLoading" type="table" ref="skeleton">
        </v-skeleton-loader>
        <v-data-table 
            v-else
            class="elevation-1"
            dense
            :items="data"
            :headers="dataHeader">
            <template v-slot:item.Index="{item}">
                {{ data.indexOf(item)+1 }}
            </template>
            <template v-slot:top>
                <v-toolbar flat height="autp">
                    <v-row class="ma-3">
                        <v-toolbar-title class="font-weight-bold text-h4 my-3">
                            Claim Reward
                        </v-toolbar-title>
                    </v-row>
                </v-toolbar>
            </template>
           
            <template v-slot:item.claim_created_at="{item}">
                <span>
                    {{ convertTimeZone(item.claim_created_at) }}
                </span>
            </template>
            <template v-slot:item.name="{item}">
                <router-link :to="{name:'PageRewardDetail',params:{id:item.reward_id}}">
                    {{ item.name }}
                </router-link>
            </template>
            <template v-slot:item.status="{item}">
                <router-link :to="{name:'PageClaimRewardDetail',params:{id:item.user_reward_transition_id}}">
                    <span v-if="item.status=='Pending'">
                        In Progress
                    </span>
                    <span v-else>
                        {{ item.status }}

                    </span>
                </router-link>
            </template>
        </v-data-table> 
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
export default {
    components:{
    //
    },
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
        data:null,
        dataHeader:[
            {
                text:"#",
                value:'Index',
                align:'start',
            },
            {
                text:'User',
                value:'username'
            },
            {
                text:'Email',
            value:'email',
            },
            {
                text:'Mobile',
                value:'mobile',
            },
            {
                text:'Reward',
                value:'name',
            },
            {
                text:'Quantity',
                value:'reward_quantity'
            },
            {
                text:'Total Required Points',
                value:'points',
            },
            {
                text:'Status',
                value:'status',
            },
            {
                text:'Claim Date',
                value:'claim_created_at'
            },
          
        ],
        isPending:false,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class=="getAllRewardTransition"){
                this.data = resp.data;
            }
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){    
            let fetchReferralApi = this.fetchReferral();
            this.$api.fetch(fetchReferralApi);
        },
        fetchReferral(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET"
            tempApi.url = process.env.VUE_APP_SERVER_API+"/referral/rewards/transition/all";
            return tempApi;
        },
       
        validateInput(){
            this.isPending=true;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        submit(){
            this.isPending = false;
            let reApplyCouponApi = this.reApplyCoupon();
            this.$api.fetch(reApplyCouponApi);
        },
        convertTimeZone(time){
            return this.$moment(time).format('LLL');
        },
    }
}
</script>